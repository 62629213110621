



import { VeeValidateConfig } from 'vee-validate/dist/types/config';
import { Component, Vue } from 'vue-property-decorator';
import store from '../../../../store';
import VueRouter from 'vue-router';
import VeeValidate from '../../../../plugins/vee-validate';
import { component } from 'vue/types/umd';
import { SecretQuestAns } from '../../../../../types/typescript-axios/api';
import StepNavi from '../../../../components/contents/stepNavi/StepNavi.vue';

@Component({
  mixins: [ VeeValidate ],
  components: {
    StepNavi,
  },
})
export default class LoginForgetPasswordEditPassword extends Vue {
  private responseStatus: number = 200;
  private errorMessage: boolean = false;
  private responseStatusText: string = '';
  private get stepNavi(): string {
    const $parent: any = this.$parent;
    return $parent.stepNavi;
  }

  private get mail(): string {
    const $parent: any = this.$parent;
    return $parent.mail;
  }
  private set mail(value: string) {
    this.inputMail(value);
  }

  private inputMail(value: string) {
    const $parent: any = this.$parent;
    $parent.setMail(value);
  }

  private get secretQuestAans(): string {
    const $parent: any = this.$parent;
    return $parent.secretQuestAans;
  }
  private set secretQuestAans(value: string) {
    this.inputSecretQuestAans(value);
  }

  private inputSecretQuestAans(value: string) {
    const $parent: any = this.$parent;
    $parent.setSecretQuestAans(value);
  }

  private async onSubmit() {
    const $observer: any = this.$refs.observer;
    $observer.reset();
    $observer.validate().then(async (result: boolean) => {
      if (result) {
        const id: any = this.$route.params.key;
        const params: SecretQuestAns = {};
        params.mailaddress = this.mail;
        params.secret_quest_ans = this.secretQuestAans;
        await store.dispatch('account/question', {id, params})
        .then((response: any) => {
          this.$router.push('confirm/');
        }, (error: any) => {
          this.errorMessage = true;
        });
      } else {
        this.errorMessage = true;
        Vue.nextTick()
        .then(() => {
          const errorDome: any = this.$refs.errorMessage;
          window.scrollTo({
            top: window.pageYOffset + errorDome.getBoundingClientRect().top,
            behavior: 'smooth',
          });
        });
      }
    });
  }
}
